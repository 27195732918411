<template>
  <div class="order-particulars">
    <div class="header" :class="setCornerMark(form.orderStatus)">
      <div class="logo">2025北京普惠健康保</div>
      <div class="status">
        <img src="../../assets/images/policy/succeed.png">
        <span>{{setStatusText(form)}}</span>
      </div>
      <div class="identity-info">
        <div class="identity-info-top flex">
          <span>承保单位</span>
        </div>
        <div class="identity-info-bottom flex">
          <span v-if="form.companyName">{{form.companyName}}</span>
          <span v-else>--</span>
        </div>
      </div>
    </div>
    <div class="order-message">
      <div class="order-message-title flex">
        <span class="name">被保人信息</span>
      </div>
      <div class="order-message-content">
        <div class="order-message-content-item">
          <span class="name">姓名：</span>
          <span>{{form.insuredName}}</span><span @click="goPdf()" v-if="form.elePolicyUrl"> | 电子保单 <van-icon name="arrow" /></span>
        </div>
        <div class="order-message-content-item">
          <span class="name">证件类型：</span>
          <span>{{form.identityType | identityType}}</span>
        </div>
        <div class="order-message-content-item">
          <span class="name">证件号码：</span>
          <span>{{form.identityCode}}</span>
        </div>
        <div class="order-message-content-item">
          <span class="name">保障期限：</span>
          <span v-if="form.effectDateFormat&&form.effectEndDateFormat">{{form.effectDateFormat}} - {{form.effectEndDateFormat}}</span>
          <span v-else>--</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { day } from '@/utils'
export default {
  name: '',
  data () {
    return {
      form: {
        orderStatus: 401,
        payStatus: 2
      },
      status: 1,
      orderId: ''
    }
  },
  created () {
    let { orderId } = this.$route.query
    this.orderId = orderId
  },
  mounted () {
    this.policyDetails()
  },
  methods: {
    goPdf (row) {
      console.log(this.form.elePolicyUrl.indexOf('http'))
      if (this.form.elePolicyUrl.indexOf('http') > -1) {
        this.$router.push({ path: '/pdf', query: { url: this.form.elePolicyUrl } })
      } else {
        window.location.href = 'https://hmbcity.webao99.com/bj24/front-insured/#/personalMerge?css=no'
      }
      // window.location.href = 'http://f4.youbaolian.cn/chexian/electronicInsurance/9803/23825/9803_10121003901809327131.PDF'
    },
    setCornerMark (row) {
      if (!row.policyCode) {
        return 'succeed'
      } else if (row.policyCode && (row.effectDate > new Date().getTime())) {
        return 'succeed'
      } else if (row.policyCode && (row.effectDate < new Date().getTime()) && (row.effectEndDate > new Date().getTime())) {
        return 'succeed'
      } else if (row.policyCode && (row.effectEndDate < new Date().getTime())) {
        return 'cancel'
      } else {
        return 'succeed'
      }
    },
    setStatusText (row) {
      if (!row.policyCode) {
        return '生成中'
      } else if (row.policyCode && (row.effectDate > new Date().getTime())) {
        return '待生效'
      } else if (row.policyCode && (row.effectDate < new Date().getTime()) && (row.effectEndDate > new Date().getTime())) {
        return '已生效'
      } else if (row.policyCode && (row.effectEndDate < new Date().getTime())) {
        return '已失效'
      } else {
        return '生成中'
      }
    },
    policyDetails () {
      let params = {
        orderId: this.orderId
      }
      this.$API.policyDetails(params).then(res => {
        console.log(res.data)
        res.data.effectDateFormat = (res.data.effectDate && day.format(new Date(res.data.effectDate), 'YYYY/MM/DD')) || ''
        res.data.effectEndDateFormat = (res.data.effectEndDate && day.format(new Date(res.data.effectEndDate), 'YYYY/MM/DD')) || ''
        this.form = res.data
        this.status = res.data.tocBusinessStatus
      }).catch(err => {
      })
    }
  }
}
</script>
<style lang="less" scoped>
.order-particulars {
  font-family: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif";
  min-height: 100vh;
  background-color: #f7f7f7;
  overflow: hidden;
  .header {
    width: 686px;
    // height: 450px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 16px;
    overflow: hidden;
    padding: 36px 32px;
    &.succeed {
      background: linear-gradient(224deg, #ff7108 0%, #feb770 100%);
      position: relative;
      &::before {
        content: "";
        display: block;
        background-image: url("../../assets/images/policy/succeedBG.png");
        background-size: 686px 318px;
        width: 686px;
        height: 318px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }
    }
    &.cancel {
      background: linear-gradient(45deg, #bbc1cc, #868d97);
      position: relative;
      &::before {
        content: "";
        display: block;
        background-image: url("../../assets/images/policy/loseBG.png");
        background-size: 686px 318px;
        width: 686px;
        height: 318px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
      }
    }
    .logo {
      font-size: 36px;
      font-weight: 600;
      color: #ffffff;
      line-height: 36px;
    }
    .status {
      display: flex;
      align-items: center;
      margin-top: 32px;
      img {
        width: 56px;
        height: 56px;
        margin-right: 15px;
      }
      span {
        font-size: 48px;
        color: #fff;
      }
    }
    .identity-info {
      margin-top: 28px;
      border-radius: 8px;
      padding: 20px;
      background: rgba(250, 250, 250, 0.7);
      .identity-info-top {
        font-size: 24px;
        color: #333333;
        line-height: 24px;
      }
      .identity-info-bottom {
        font-size: 24px;
        color: #666666;
        line-height: 24px;
        margin-top: 16px;
      }
    }
    .money {
      text-align: right;
      color: #fff;
      margin-top: 32px;
      font-size: 30px;
      span + span {
        margin-left: 40px;
      }
    }
  }
  .order-message {
    width: 686px;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 24px;
    border-radius: 20px;
    padding: 0 28px;
    padding-bottom: 40px;
    .order-message-title {
      padding: 38px 0 18px 0;
      .name {
        font-size: 36px;
        color: #333;
        font-weight: 600;
      }
      .details {
        font-size: 26px;
        color: #7d8ba2;
      }
    }
    .order-message-content {
      padding: 40px 24px;
      background: #fafafa;
      border-radius: 8px;
      .order-message-content-item {
        color: #666;
        font-size: 28px;
      }
      .order-message-content-item + .order-message-content-item {
        margin-top: 18px;
      }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
